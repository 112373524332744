import React, {useState, useEffect} from 'react';
import '../styles/ContactModal.css';
import modalIcon from '../images/modal.svg';
import InputMask from 'react-input-mask';
import {Store} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import 'react-phone-input-2/lib/style.css';
import Policy from "../files/Политика обработки персональных данных.rtf";
import checkIcon from "../images/App/checkCircle.svg";
import errorIcon from "../images/App/errorCircle.svg";
import axios from "axios";

export default function DemonstrationModal({openModal, size}) {
	const [phone, setPhone] = useState('');
	const [name, setName] = useState('')
	const [email, setEmail] = useState('');
	const [comment, setComment] = useState('');
	const [emptyPhone, setEmptyPhone] = useState(true);
	const [disabled, setDisabled] = useState(true);
	const [loading, setLoading] = useState(true);
	const [permission, setPermission] = useState(false);
	const [businessSize, setBusinessSize] = useState([
		{
			title: "Малое и среднее предпринимательство: стоимость от площади застройки 1 м2 от 10 р.",
			value: 'small',
			checked: true,
		},
		{
			title: "Крупное предпринимательство: стоимость за лицензию от 3 500 р.",
			value: 'big',
			checked: false,
		}]
	);

	useEffect(() => {
		if (!emptyPhone) {
			setDisabled(!permission);
		} else {
			setDisabled(true);
		}
	}, [permission, emptyPhone])

	const validatePhone = (phone) => {
		setPhone(phone);
		if (phone.replaceAll('_', '').length === 15) {
			setEmptyPhone(false);
		} else {
			setEmptyPhone(true);
			// setErrorMessage(!mobileView ? 'Поля обязательны для заполнения' : 'Обязательно для заполнения');
		}
	}

	const submitData = async () => {
		try {
			const request = {};
			request.name = name;
			request.phone = phone;
			request.email = email;
			request.comment = comment;
			request.subject = "Рассчет стоимости"
			setLoading(true);
			const response = await axios.post(
				`/api/send/orderBill`,
				{
					data: JSON.stringify(request)
				},
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					}
				}
			);
			const {status, message} = response.data;
			setLoading(false);
			if (status !== false) {
				Store.addNotification({
					content: <div className="notification">
						<img src={checkIcon} alt=""/>
						<div>
							<p>Ожидайте звонка</p>
							<p>Мы свяжемся с вами в ближайшее время</p>
						</div>
					</div>,
					insert: "top",
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
				openModal(false);
				setPhone('');
				setEmail('');
				setComment('');
			} else {
				openModal(false);
				Store.addNotification({
					content: <div className="notification">
						<img src={errorIcon} alt=""/>
						<div>
							<p>Заявка не отправлена</p>
							<p>{message}</p>
						</div>
					</div>,
					insert: "top",
					showIcon: true,
					container: "top-right",
					animationIn: ["animate__animated", "animate__fadeIn"],
					animationOut: ["animate__animated", "animate__fadeOut"],
					dismiss: {
						duration: 5000,
					},
				});
			}
		} catch (e) {
			Store.addNotification({
				content:<div className="notification"> {e} </div>,
				insert: "top",
				showIcon: true,
				container: "top-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 5000,
				},
			});
		}
	}

	return (
		<div className="app-contactModal">
			<div className="app-contactModal-content" onClick={(e) => {
				e.stopPropagation()
			}}>
				<div style={{position: "relative"}}>
                <span className="app-contactModal-content-close-button"
											onClick={() => openModal(false)}> &#10006; </span>
				</div>
				<div style={{position: "relative"}}>
					<img src={modalIcon} alt={""}/>
					<h2>Оставьте заявку для расчета стоимости</h2>
					{size.width >= 768 ?
						<span className="app-contactModal-content-subTitle">Заполните форму, мы cвяжемся с вами и ответим вам на все интересующие вас вопросы</span> : null}
				</div>
				<div className="app-contactModal-input-header">ИМЯ</div>
				<input
					type='text'
					value={name}
					placeholder='Имя'
					maxLength={40}
					className="app-contactModal-input"
					onChange={(e) => {
						setName(e.target.value);
					}}
				/>
				<div className="app-contactModal-input-header">EMAIL</div>
				<input
					type='email'
					value={email}
					placeholder='E-mail'
					maxLength={40}
					className="app-contactModal-input"
					onChange={(e) => {
						setEmail(e.target.value);
					}}
				/>
				<div className="app-contactModal-input-header">НОМЕР ТЕЛЕФОНА</div>
				<InputMask
					mask="+7(999)999-9999"
					maskChar='_'
					value={phone}
					onChange={(e) => {
						validatePhone(e.target.value)
					}}
				>
					{() => <input className="app-contactModal-input"/>}
				</InputMask>
				<div className="app-contactModal-input-header">КОММЕНТАРИЙ</div>
				<textarea
					type='text'
					value={comment}
					placeholder='Вы можете написать любой комментарий или указать время в которое вам комфортнее, чтобы мы связались с вами'
					maxLength={500}
					rows='5'
					className="app-contactModal-input"
					onChange={(e) => {
						setComment(e.target.value);
					}}
				/>
				<div className='app-contactModal-policy-block'>
					<input
						type="checkbox"
						style={{display: "none"}}
						id="input"
						checked={permission}
					/>
					<label htmlFor="input" onClick={() => !setPermission(!permission)}/>
					<span className="app-contactModal-input-header">Я согласен на&nbsp;</span>
					<a
						href={Policy}
						download="Политика обработки персональных данных.rtf"
						style={{color: 'rgba(76, 172, 233, 1)'}}
						className="app-contactModal-input-header"
					>обработку
					</a>
					<span className="app-contactModal-input-header">&nbsp;своих&nbsp;</span>
					<span className="app-contactModal-input-header"
								style={{marginLeft: size.width < 768 && '35px'}}>персональных данных </span>
				</div>
				<button
					type='primary'
					loading={loading}
					onClick={() => submitData()}
					disabled={disabled}
					style={{backgroundColor: disabled ? 'grey' : 'rgba(76, 172, 233, 1)', color: 'white', width: '100%'}}
				>
					Оставить заявку
				</button>
			</div>
		</div>
	);
}