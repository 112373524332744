import React from 'react';
import '../styles/YouTubeBlock.css';
import videoPoster from '../images/Youtube/videoPoster.png';
import phoneIcon from '../images/Youtube/phoneIcon.png';
import phoneIconMob from '../images/Youtube/phoneIconMob.png';
import youTubeVideo from '../videos/video.mp4';

export default function YouTubeBlock({size, refProp}) {

    return (
        <section className='app-main-youTube' ref={refProp}>
            <video
              id="my-video"
              className="video-js"
              controls
              preload="false"
              poster={videoPoster}
            >
                <source src={youTubeVideo} type="video/mp4"/>
                <p className="vjs-no-js">
                    To view this video please enable JavaScript, and consider upgrading to a
                    web browser that
                    <a href="https://videojs.com/html5-video-support/" target="_blank"
                    >supports HTML5 video</a
                    >
                </p>
            </video>
        </section>
    );
}