import React, {useState, useEffect, useRef} from "react";
import "../styles/VideoSlider.css";
import playBtn from "../images/App/playButton.svg";
import pauseBtn from "../images/App/pauseButton.svg";
import replayBtn from "../images/App/rePlayButton.svg";
import Slider from "react-slick";

export default function VideoSlider({items}) {
    const [activeSlide, setActiveSlide] = useState(0);
    const [playerBtn, setPlayerBtn] = useState(playBtn);
    const [autoPlay, setAutoPlay] = useState(false);

    let sliderRef = useRef(null);

    const manageVideo = () => {
        if (playerBtn === playBtn) {
            sliderRef.slickPlay();
            setPlayerBtn(pauseBtn);
        }
        if (playerBtn === pauseBtn) {
            sliderRef.slickPause();
            setPlayerBtn(playBtn);
        }
        if (playerBtn === replayBtn) {
            sliderRef.slickPause();
            sliderRef.slickGoTo(0, true);
            sliderRef.slickPlay();
        }
    }

    useEffect(() => {
        if (activeSlide + 1 === items.length) {
            setTimeout(() => setPlayerBtn(replayBtn), 2000);
        } else {
            if (playerBtn === replayBtn ) {
                setPlayerBtn(playBtn);
            }
        }
    }, [activeSlide]);

    const settings = {
        infinite: false,
        dots: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        autoplaySpeed: 2000,
        cssEase: "linear",
        centerMode: true,
        className: "center",
        initialSlide: 0,
        beforeChange: (previousActiveSlide, activeSlide) => setActiveSlide(activeSlide),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    centerPadding: "20px",
                }
            },
        ]
    };

    return (
        <div className="slider-container">
            <span>{autoPlay}</span>
            <Slider {...settings} style={{minWidth: '280px'}} ref={slider => (sliderRef = slider)} appendDots={(dots) => (
                <div>
                    <div className="slider-dots-wrapper" onClick={() => sliderRef.slickPause()}>
                        <ul className="slider-dots"> {dots} </ul>
                    </div>
                    <div className="videoPlayBtn" onClick={() => manageVideo()}>
                        <img src={playerBtn} alt=""/>
                    </div>
                </div>
            )}>
                {items.map(item => (
                    <div>
                        <div className={`video-slider-item ${item.key}`}>
                            {item.block(item)}
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
// const [width, setWidth] = useState(0);
// const [offset, setOffset] = useState(0);
// const [slideCount, setSlideCount] = useState(0);
// const [lastItem, setLastItem] = useState('left');
// const [arrowInActive, setArrowInActive] = useState('left');
//
// const Slide = forwardRef((props, ref) =>
//     React.Children.map(props.children, child => (
//         <div className="simple-slide" ref={ref} style={{width: width}}>
//             {child}
//         </div>
//     ))
// );
//
// const slideRef = createRef();
// const wrapperRef = createRef();
// const slideWindowRef = createRef();
//
// useEffect(() => {
//     setArrowInActive(lastItem);
//     console.log(props)
// }, [lastItem])
//
// useLayoutEffect(() => {
//     const rect = slideWindowRef.current.getBoundingClientRect();
//     // console.log(slideRef.current.parentNode)
//     setWidth(rect.width);
//     setSlideCount(props.children.length);
//     slideWindowRef.current.style.width = rect.width + "px";
// }, [slideRef, offset, slideWindowRef, props]);
//
// const {
//     leftCallback = () => {
//     }, rightCallback = () => {
//     }
// } = props;
//
// const leftClick = e => {
//     e.preventDefault();
//     let currentRight = wrapperRef.current.style.right || 0;
//     currentRight = parseInt(currentRight, 10);
//     setLastItem('');
//     if (currentRight === 1 * width) {
//         setLastItem('left');
//     }
//     if (currentRight === 0) {
//         setLastItem('left');
//         return false;
//     }
//     const newOffset = currentRight - width;
//     wrapperRef.current.style.right = newOffset + "px";
//     setOffset(newOffset);
//     leftCallback();
// };
//
// const rightClick = e => {
//     e.preventDefault();
//     let currentRight = wrapperRef.current.style.right || 0;
//     currentRight = parseInt(currentRight, 10);
//     setLastItem('');
//     if (currentRight === (slideCount - 2) * width) {
//         setLastItem('right');
//     }
//     if (currentRight === (slideCount - 1) * width) {
//         setLastItem('right');
//         return false;
//     }
//
//     const newOffset = offset + width;
//     wrapperRef.current.style.right = newOffset + "px";
//     setOffset(newOffset);
//     rightCallback();
// };
//
// return (
//     <div className="main-slider">
//         <button className={`slide-left ${arrowInActive === 'left' ? 'inActiveArr' : 'activeArr'}`}
//                 onClick={leftClick}>
//         </button>
//         <div className="slide-window" ref={slideWindowRef}>
//             <div className="slide-wrapper" ref={wrapperRef}>
//                 <Slide ref={slideRef}>{props.children}</Slide>
//             </div>
//         </div>
//         <div className="sliderBtnWrapper">
//             <div className="sliderDotWrapper">
//                 {props.children.map((item) => (
//                     <div onClick={() => console.log(item)}>
//                         <div className="sliderDot"/>
//                     </div>
//                 ))}
//             </div>
//             <div className="videoPlayBtn">
//                 <img src={videoButton} alt=""/>
//             </div>
//         </div>
//         <button className={`slide-right ${arrowInActive === 'right' ? 'inActiveArr' : 'activeArr'}`}
//                 onClick={rightClick}>
//         </button>
//     </div>
// );
}