import React, {useState, useEffect, useRef} from "react";
import "../styles/MobSlider.css";
import "../styles/VideoSlider.css";
import playBtn from "../images/App/playButton.svg";
import pauseBtn from "../images/App/pauseButton.svg";
import replayBtn from "../images/App/rePlayButton.svg";
import Slider from "react-slick";

export default function MobSlider({items, size}) {
	const [activeSlide, setActiveSlide] = useState(0);
	const [playerBtn, setPlayerBtn] = useState(playBtn);
	const [autoPlay, setAutoPlay] = useState(false);

	let sliderRef = useRef(null);

	const manageVideo = () => {
		if (playerBtn === playBtn) {
			sliderRef.slickPlay();
			setPlayerBtn(pauseBtn);
		}
		if (playerBtn === pauseBtn) {
			sliderRef.slickPause();
			setPlayerBtn(playBtn);
		}
		if (playerBtn === replayBtn) {
			sliderRef.slickPause();
			sliderRef.slickGoTo(0, true);
			sliderRef.slickPlay();
		}
	}

	useEffect(() => {
		if (activeSlide + 1 === items.length) {
			setTimeout(() => setPlayerBtn(replayBtn), 2000);
		} else {
			if (playerBtn === replayBtn ) {
				setPlayerBtn(playBtn);
			}
		}
	}, [activeSlide]);

	const settings = {
		infinite: true,
		dots: true,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 500,
		autoplaySpeed: 2000,
		cssEase: "linear",
		variableWidth: true,
		adaptiveHeight: true,
		centerMode: true,
		className: "center",
		initialSlide: 0,
		centerPadding: '20px',
		responsive: [
			{
				breakpoint: 650,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				}
			},
		]
	};

	return (
		<div className="mob-slider-container">
			<span>{autoPlay}</span>
			<Slider style={{width: size.width * 0.8}} {...settings} ref={slider => (sliderRef = slider)} appendDots={(dots) => (
				<div>
					<div className="slider-dots-wrapper" onClick={() => sliderRef.slickPause()}>
						<ul className="slider-dots"> {dots} </ul>
					</div>
					<div className="videoPlayBtn" onClick={() => manageVideo()}>
						<img src={playerBtn} alt=""/>
					</div>
				</div>
			)}>
				{items.map(item => item.context ?
					<div style={{width:  size.width * 0.8}}>{item.context}</div> :
					<div style={{width: size.width * 0.8}}>
						<div className={item.key} >
							<img src={item.img} alt=""/>
							{item.arrows.map(el => (
								<div className={el.key}>
									<img src={el.arr} alt=""/>
									<span>{el.text}</span>
								</div>
							))}
						</div>
					</div>)
				}
			</Slider>
		</div>
	);
}