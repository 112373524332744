import React, {useRef} from "react";
import Slider from "react-slick";
import '../styles/SliderBlock.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SliderBlock({items}) {
    let sliderRef = useRef(null);

    const settings = {
        infinite: false,
        dots: true,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        speed: 500,
        autoplaySpeed: 2000,
        cssEase: "linear",
        variableWidth: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };
    return (
        <div className="slider-container">
            <Slider {...settings} ref={slider => (sliderRef = slider)} appendDots={(dots) => (
              <div>
                  <div className="slider-dots-wrapper">
                      <ul className="slider-dots"> {dots} </ul>
                  </div>
              </div>
            )}>
                {items.map(item => (
                    <div style={{width: 250, boxShadow: '0px 4px 4px 0px #00000026'}}>
                        <div className={`app-main-progress-item ${item.key}`}>
                            <div>
                                <img src={item.img} alt=""/>
                                {item.paragraphs.map(paragraph => (
                                    <p>{paragraph}</p>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}
