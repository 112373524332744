import React, {useState, useEffect} from 'react';
import '../styles/PanelBlock.css';
import AirPlaneIcon from './Icons/Panel/AirPlaneIcon';
import InfoIcon from './Icons/InfoIcon';
import StatisticIcon from './Icons/StatisticIcon';
import arrowUp from "../images/arrowUp.svg";
import arrowDown from "../images/arrowDown.svg";
import pArrowDown from "../images/Panel/panelArrowDown.svg";
import panel1 from "../images/Panel/panel1.png";
import panel11 from "../images/Panel/panel1-1.png";
import panel12 from "../images/Panel/panel1-2.png";
import panel13 from "../images/Panel/panel1-3.png";
import panel14 from "../images/Panel/panel1-4.png";
import panel15 from "../images/Panel/panel1-5.png";
import panel16 from "../images/Panel/panel1-6.png";
import panel17 from "../images/Panel/panel1-7.png";
import panel18 from "../images/Panel/panel1-8.png";
import panel19 from "../images/Panel/panel1-9.png";
import panel2 from "../images/Panel/panel2.png";
import panel3 from "../images/Panel/panel3.png";
import airPlaneFilledIcon from "../images/Panel/airPlaneIcon.svg";
import lteIcon from "../images/Panel/LTEIcon.svg";
import arrowToRight from '../images/Panel/panelArrowToRight.svg';
import arrowToRight2 from '../images/Panel/panelArrowToRight2.svg';
import arrowToRight3 from '../images/Panel/panelArrowToRight3.svg';
import arrowToRight4 from '../images/Panel/panelArrowToRight4.svg';
import arrowToRight5 from '../images/Panel/panelArrowToRight5.svg';
import arrowToLeft from '../images/Panel/panelArrowToLeft.svg';
import arrowToLeft2 from '../images/Panel/panelArrowToLeft2.svg';
import arrowToLeft3 from '../images/Panel/panelArrowToLeft3.svg';
import arrowToLeft4 from '../images/Panel/panelArrowToLeft4.svg';
import arrowToLeft5 from '../images/Panel/panelArrowToLeft5.svg';
import arrowToLeftTablet from '../images/Panel/panelArrowToLeftTablet.svg';
import arrowToLeft6 from '../images/Panel/panelArrowToLeft6.svg';
import MobSlider from './MobSlider';
import p21Mob from '../images/Panel/p21-mob.svg';
import p22Mob from '../images/Panel/p22-mob.svg';
import p23Mob from '../images/Panel/p23-mob.svg';
import p25Mob from '../images/Panel/p25-mob.svg';
import p26Mob from '../images/Panel/p26-mob.svg';
import p21ImgMob from '../images/Panel/p22ImgMob.png';
import p22ImgMob from '../images/Panel/p23ImgMob.png';
import p31ImgMob from '../images/Panel/p31ImgMob.png';
import p32ImgMob from '../images/Panel/p32ImgMob.png';
import p31Mob from '../images/Panel/p31-mob.svg';
import p32Mob from '../images/Panel/p32-mob.svg';
import p33Mob from '../images/Panel/p33-mob.svg';
import p34Mob from '../images/Panel/p34-mob.svg';
import p35Mob from '../images/Panel/p35-mob.svg';
import p37Mob from '../images/Panel/p37-mob.svg';
import p38Mob from '../images/Panel/p38-mob.svg';
import p39Mob from '../images/Panel/p39-mob.svg';
import p390Mob from '../images/Panel/p390-mob.svg';

export default function PanelBlock({size, refProp}) {
	const [items, setItems] = useState([
		{
			key: 'pan1',
			title: 'Работа с замечаниями в офлайн режиме',
			img: AirPlaneIcon,
			checked: true,
			slider: [{
				context: <div className="app-main-panel-slide-wrapper">
					<div className="grid-text-wrapper">
						<img src={pArrowDown} alt=""/>
						<span>Передвинуться по структуре, выбрать необходимый проект. Перейти на вкладку Документация и открыть файл в системе</span>
					</div>
					<div className="grid-img-wrapper">
						<img className="main-picture" src={panel11} alt=""/>
						<img className="icon-picture small" src={lteIcon} alt="" style={{marginTop: '30px'}}/>
						<img className="main-picture" src={panel12} alt=""/>
					</div>
					<img className="icon-picture small" src={lteIcon} alt=""/>
					<div className="grid-text-wrapper">
						<img src={pArrowDown} alt=""/>
						<span>Дождаться полной загрузки документов</span>
					</div>
					<img className="main-picture" src={panel13} alt=""/>
				</div>
			}, {
				context: <div className="app-main-panel-slide-wrapper">
					<div className="grid-text-wrapper">
						<img src={pArrowDown} alt=""/>
						<span>Включить авиарежим. Добавить замечания/фотографии на проект. Заполнить всю необходимую информацию. Изменения будут внесены после восстановления сети</span>
					</div>
					<div className="grid-img-wrapper">
						<img className="main-picture" src={panel14} alt=""/>
						<img className="icon-picture" src={airPlaneFilledIcon} alt="" style={{marginTop: '30px'}}/>
						<img className="main-picture" src={panel15} alt=""/>
					</div>
					<img className="icon-picture" src={airPlaneFilledIcon} alt=""/>
					<img className="main-picture" src={panel16} alt=""/>
				</div>
			},
				{
					context: <div className="app-main-panel-slide-wrapper">
						<div className="grid-text-wrapper">
							<img src={pArrowDown} alt=""/>
							<span>Подключить телефон к сети. В главном меню выбрать “Статус синхронизации”. Замечания автоматически будут отправлены в систему</span>
						</div>
						<div className="grid-img-wrapper">
							<img className="main-picture" src={panel17} alt=""/>
							<img className="icon-picture small" src={lteIcon} alt="" style={{marginTop: '30px'}}/>
							<img className="main-picture" src={panel18} alt=""/>
						</div>
						<img className="icon-picture small" src={lteIcon} alt=""/>
						<div className="grid-text-wrapper">
							<img src={pArrowDown} alt=""/>
							<span>При необходимости самостоятельно загрузить файлы</span>
						</div>
						<img className="main-picture" src={panel19} alt="" style={{width: '160px'}}/>
					</div>
				}
			],
			block: () => <div style={{display: 'flex', justifyContent: 'space-between'}}>
				<div className="grid">
					<div className="grid-text-wrapper g1">
						<img src={pArrowDown} alt=""/>
						<span>Передвинуться по структуре, выбрать необходимый проект. Перейти на вкладку Документация и открыть файл в системе</span>
					</div>
					<div className="grid-text-wrapper g2">
						<img src={pArrowDown} alt=""/>
						<span>Дождаться полной загрузки документов</span>
					</div>
					<div className="grid-img-wrapper g3">
						<img className="main-picture" src={panel11} alt=""/>
						<img className="icon-picture small" src={lteIcon} alt=""/>
					</div>
					<div className="grid-img-wrapper g4">
						<img className="main-picture" src={panel12} alt=""/>
						<img className="icon-picture small" src={lteIcon} alt=""/>
					</div>
					<div className="grid-img-wrapper g5">
						<img className="main-picture" src={panel13} alt=""/>
					</div>
					<div className="grid-text-wrapper g6">
						<img src={pArrowDown} alt=""/>
						<span>Включить авиарежим. Добавить замечания/фотографии на проект. Заполнить всю необходимую информацию. Изменения будут внесены после восстановления сети</span>
					</div>
					<div className="grid-img-wrapper g7">
						<img className="main-picture" src={panel14} alt=""/>
						<img className="icon-picture" src={airPlaneFilledIcon} alt=""/>
					</div>
					<div className="grid-img-wrapper g8">
						<img className="main-picture" src={panel15} alt=""/>
						<img className="icon-picture" src={airPlaneFilledIcon} alt=""/>
					</div>
					<div className="grid-img-wrapper g9">
						<img className="main-picture" src={panel16} alt=""/>
					</div>
					<div className="grid-text-wrapper g10">
						<img src={pArrowDown} alt=""/>
						<span>Подключить телефон к сети. В главном меню выбрать “Статус синхронизации”. Замечания автоматически будут отправлены в систему</span>
					</div>
					<div className="grid-text-wrapper g11">
						<img src={pArrowDown} alt=""/>
						<span>При необходимости самостоятельно загрузить файлы</span>
					</div>
					<div className="grid-img-wrapper g12">
						<img className="main-picture" src={panel17} alt=""/>
						<img className="icon-picture small" src={lteIcon} alt=""/>
					</div>
					<div className="grid-img-wrapper g13">
						<img className="main-picture" src={panel18} alt=""/>
						<img className="icon-picture small" src={lteIcon} alt=""/>
					</div>
					<div className="grid-img-wrapper g14">
						<img className="main-picture" src={panel19} alt=""/>
					</div>
				</div>
				<img src={panel1} alt=""/>
			</div>,
		},
		{
			key: 'pan2',
			title: 'Учет штрафов',
			img: InfoIcon,
			checked: false,
			slider: [{
				key: 'pan2-wrap',
				img: p21ImgMob,
				arrows: [{
					text: 'Начисление штрафов в зависимости от кол-ва повторно открытых замечаний',
					arr: p21Mob,
					key: 'p21-inn',
				},
					{
						text: 'Автоматическое проставление признака штраф',
						arr: p22Mob,
						key: 'p22-inn',
					},
					{
						text: 'Отчетная форма предписания',
						arr: p23Mob,
						key: 'p23-inn',
					},],
			}, {
				key: 'pan3-wrap',
				img: p22ImgMob,
				arrows: [{
					text: 'Разные механики начисления штрафа в зависимости от отдела автора и исполнителя',
					arr: p21Mob,
					key: 'p24-inn',
				},
					{
						text: 'Отчетная форма акта фиксации',
						arr: p25Mob,
						key: 'p25-inn',
					},
					{
						text: 'Выдача корректирующих мероприятий',
						arr: p26Mob,
						key: 'p26-inn',
					},
				],
			},
			],
			block: (size) => <div>
				<div className="blueLine" style={{maxWidth: size.width * 0.885}}/>
				<div className="app-panel-2">
					<img className="app-panel-main-img" src={panel2} alt=""/>
					<div className="text-wrapper topLeft">
						<div>Начисление штрафов в зависимости от кол-ва повторно открытых замечаний</div>
						<img src={arrowToRight} alt=""/>
					</div>
					<div className="text-wrapper middleLeft">
						<div>Отчетная форма предписания</div>
						<img src={arrowToRight} alt=""/>
					</div>
					<div className="text-wrapper bottomLeft">
						<div>Автоматическое проставление признака штраф</div>
						<img src={arrowToRight2} alt=""/>
					</div>
					<div className="text-wrapper topRight">
						<div>Разные механики начисления штрафа в зависимости от отдела автора и исполнителя</div>
						<img src={arrowToLeft} alt=""/>
					</div>
					<div className="text-wrapper middleRight">
						<div>Отчетная форма акта фиксации</div>
						<img src={arrowToLeft2} alt=""/>
					</div>
					<div className="text-wrapper bottomRight">
						<div>Выдача корректирующих мероприятий</div>
						<img src={arrowToLeft3} alt=""/>
					</div>
				</div>
			</div>
		},
		{
			key: 'pan3',
			title: 'Отчет нарушений',
			img: StatisticIcon,
			checked: false,
			slider: [{
				key: 'pan4-wrap',
				img: p31ImgMob,
				arrows: [{
					text: 'Объем к удержанию',
					arr: p31Mob,
					key: 'p31-inn',
				},
					{
						text: 'Штраф',
						arr: p32Mob,
						key: 'p32-inn',
					},
					{
						text: 'Статус замечания',
						arr: p33Mob,
						key: 'p33-inn',
					},
					{
						text: 'Требует/не требует устранения',
						arr: p34Mob,
						key: 'p34-inn',
					},
					{
						text: 'Исполнитель: собственные силы/субподрядные организации',
						arr: p35Mob,
						key: 'p35-inn',
					},
					{
						text: 'Просроченное',
						arr: p23Mob,
						key: 'p36-inn',
					},
				],
			}, {
				key: 'pan5-wrap',
				img: p32ImgMob,
				arrows: [
					{
						text: 'Комментарии и фотофиксация',
						arr: p37Mob,
						key: 'p37-inn',
					},
					{
						text: 'Повторно открытое',
						arr: p38Mob,
						key: 'p38-inn',
					},
					{
						text: 'Отметка на плане (чертеже) и Вложенные файлы',
						arr: p39Mob,
						key: 'p39-inn',
					},
					{
						text: 'Департамент',
						arr: p390Mob,
						key: 'p390-inn',
					},
				],
			},
			],
			block: (size) => <div>
				<div className="blueLine" style={{maxWidth: size.width * 0.885}}/>
				<div className="app-panel-3">
					<img className="app-panel-main-img" src={panel3} alt=""/>
					<div className="text-wrapper left1">
						<div>Объем к удержанию</div>
						<div>Штраф</div>
						<img src={arrowToRight3} alt=""/>
					</div>
					<div className="text-wrapper left2">
						<div>Просроченное</div>
						<img src={arrowToRight4} alt=""/>
					</div>
					<div className="text-wrapper left3">
						<div>Исполнитель: собственные силы/субподрядные организации</div>
						<img src={arrowToRight5} alt=""/>
					</div>
					<div className="text-wrapper left4">
						<div>Статус замечания</div>
						<img src={arrowToRight} alt=""/>
					</div>
					<div className="text-wrapper left5">
						<div>Требует/не требует устранения</div>
						<img src={arrowToRight} alt=""/>
					</div>
					<div className="text-wrapper left6">
						<div>Перенесен срок</div>
						<img src={arrowToRight4} alt=""/>
					</div>
					<div className="text-wrapper right4">
						<div>Департамент</div>
						<img src={arrowToLeft} alt=""/>
					</div>
					<div className="text-wrapper right3">
						<div>Отметка на плане (чертеже)</div>
						<div>Вложенные файлы</div>
						<img src={size.width<1401 ? arrowToLeftTablet: arrowToLeft5} alt=""/>
					</div>
					<div className="text-wrapper right2">
						<div>Фотофиксация</div>
						<div>Комментарий</div>
						<img src={arrowToLeft4} alt=""/>
					</div>
					<div className="text-wrapper right1">
						<div>Повторно открыто</div>
						<img src={arrowToLeft6} alt=""/>
					</div>
				</div>
			</div>,
		},
	]);

	const onChange = (key, value) => {
		const newItems = items.map(item => {
			const newItem = {...item};
			newItem.checked = false;
			if (newItem.key === key) {
				newItem.checked = size.width < 1024 ? value : true;
			}
			return newItem;
		});
		setItems(newItems);
	}

	return (
		<section className='app-main-panel' ref={refProp}>
			<div className="header-wrapper">
				<h1>Административная панель</h1>
				<p className="page-description">Работа в офлайн режиме – мгновенная синхронизация данных</p>
				<p className="page-description">Работа с замечаниями в “ЦДС КОНТРОЛЬ” без сети</p>
			</div>
			<div className="checkbox-wrapper">
				{items.map(item => (
					<div key={item.key} className={`checkbox-item ${item.key}`}>
						<div className={`checkbox-${item.checked ? 'active' : 'inActive'}`}>
							<input type="checkbox" name="header" id={item.key} checked={item.checked}
										 onClick={(e) => onChange(item.key, e.target.checked)}/>
							<label htmlFor={item.key}>
								<div>
									<item.img />
									<span>{item.title}</span>
								</div>
								{size.width < 1024 ?
									<img src={item.checked ? arrowUp : arrowDown} alt=""/> : null}
							</label>
						</div>
						<div
							className={`checkbox-img ${item.checked ? 'active' : 'inActive'}`}>
							{size.width > 1024 || (size.width <= 1024 && !item.slider.length) ? item.block(size) :
								<MobSlider items={item.slider} key={Math.random()} size={size}/>}
						</div>
					</div>
				))}
			</div>
		</section>
	);
}