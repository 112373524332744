import React, {useState, useEffect} from 'react';
import '../styles/DetailsBlock.css';
import VideoSlider from "./VideoSlider";
import img11 from "../images/Details/detailsBlockImg1.png";
import img21 from "../images/Details/detailsBlockImg2.png";
import img31 from "../images/Details/detailsBlockImg3.png";
import img12 from "../images/Details/detailsBlockImg12.png";
import img22 from "../images/Details/detailsBlockImg22.png";
import img32 from "../images/Details/detailsBlockImg32.png";
import img33 from "../images/Details/detailsBlockImg33.png";
import menuIcon from "../images/Details/menuIcon.svg";
import listIcon from "../images/Details/listIcon.svg";
import activeIcon from '../images/Details/activeIcon.svg';
import inActiveIcon from '../images/Details/inActiveIcon.svg';

export default function DetailsBlock({openModal}) {
	const [items, setItems] = useState([
		{
			key: 'd1',
			title: '1. Создайте структуру объектов',
			img: '',
			checked: true,
			block: () => (
				<div className="app-main-details-slide-wrapper">
					<div className="app-main-details-slide-header">
						<img src={listIcon} alt=""/>
						<span>1. Создайте структуру объектов</span>
					</div>
					<div>
						<div className="app-main-details-slide-content-wrapper">
							<div className="app-main-details-slide-content">
								<div>
									<img src={menuIcon} alt=""/>
									<span>Создайте ваш проект</span>
								</div>
								<div className="blackLine"/>
								<div className="blueHeader">Загрузить планы объекта</div>
								<img src={img12} alt=""/>
							</div>
						</div>
						<img src={img11} alt=""/>
					</div>
				</div>)
		},
		{
			key: 'd2',
			title: '2. Загрузите документацию',
			img: '',
			checked: false,
			block: () => (
				<div className="app-main-details-slide-wrapper">
					<div className="app-main-details-slide-header">
						<img src={listIcon} alt=""/>
						<span>2. Загрузите документацию</span>
					</div>
					<div>
						<div className="app-main-details-slide-content-wrapper">
							<div className="app-main-details-slide-content">
								<div>
									<img src={menuIcon} alt=""/>
									<span>Просто перетащите файлы в созданную структуру</span>
								</div>
								<div className="blackLine"/>
								<div className="blueHeader">На чертеже будут отражены метки замечаний</div>
								<img src={img12} alt=""/>
							</div>
						</div>
						<img src={img21} alt=""/>
					</div>
				</div>)
		},
		{
			key: 'd3',
			title: '3. Назначьте роли пользователям',
			img: '',
			checked: false,
			block: () => (
				<div className="app-main-details-slide-wrapper">
					<div className="app-main-details-slide-header">
						<img src={listIcon} alt=""/>
						<span>3. Назначьте роли пользователям</span>
					</div>
					<div>
						<div className="app-main-details-slide-content-wrapper">
							<div className="app-main-details-slide-content">
								<div className="blueHeader">Распределите роли для каждого проекта</div>
								<img src={img12} alt=""/>
								<div style={{textAlign: 'center'}}>Добавьте сотрудников</div>
								<img src={img32} alt=""/>
								<div style={{textAlign: 'center'}}>Можно приступать к работе</div>
							</div>
						</div>
						<img src={img31} alt=""/>
					</div>
				</div>)
		},
	]);

	const onChange = (key) => {
		const newItems = items.map(item => {
			const newItem = {...item};
			newItem.checked = false;
			if (newItem.key === key) {
				newItem.checked = true;
			}
			return newItem;
		});
		setItems(newItems);
	}

	return (
		<section className='app-main-details'>
			<div className="header-wrapper">
				<h1>Как это работает на строительной площадке</h1>
				<p className="page-description">Запуск за 3 дня</p>
			</div>
			<div className="app-main-details-wrapper">
				<VideoSlider items={items}/>
				{/*{items.map(data => {*/}
				{/*    return (*/}
				{/*        <div key={data.key} className="slider">*/}
				{/*            <div className="slider-description">*/}
				{/*                <div>{data.block(data)}</div>*/}
				{/*            </div>*/}
				{/*            <img src={data.img} alt='' style={{width: data.width, height: data.height}}/>*/}
				{/*        </div>*/}
				{/*    );*/}
				{/*})}*/}
				{/*</VideoSlider>*/}
				{/*<div className='app-main-header-btns'>*/}
				{/*    <button className="white-btn" onClick={() => openModal(true)}>Бесплатная демонстрация</button>*/}
				{/*    <button className="transparent-btn" onClick={() => openModal(true)}>Рассчитать стоимость</button>*/}
				{/*</div>*/}
			</div>
		</section>
	);
}