import React, {useState, useEffect} from 'react';
import { useInView } from "react-intersection-observer";
import Slider from './Slider';
import '../styles/ProgressBlock.css';
import progress1 from '../images/Progress/progress1.svg';
import progress2 from '../images/Progress/progress2.svg';
import progress3 from '../images/Progress/progress3.svg';
import progress4 from '../images/Progress/progress4.svg';
import progress5 from '../images/Progress/progress5.svg';
import progressImg from '../images/Progress/progressImg.png';

export default function ProgressBlock({openModal, size}) {
    const items = [
        {
            key: 'p1',
            img: progress1,
            paragraphs: ['Офлайн работа', 'в приложении', <span style={{ color: '#5F5F5F', fontSize: '14px' }}>(без интернета)</span>],
        },
        {
            key: 'p2',
            img: progress2,
            paragraphs: ['Статистика', 'и дашборды'],
        },
        {
            key: 'p3',
            img: progress3,
            paragraphs: ['Неограниченное количество', 'строительных объектов', 'и пользователей'],
        },
        {
            key: 'p4',
            img: progress4,
            paragraphs: ['Персональные', 'настройки', 'пользователя',],
        },
        {
            key: 'p5',
            img: progress5,
            paragraphs: ['Открытое API', <span style={{ color: '#5F5F5F', fontSize: '14px' }}>(интеграция с любым</span>, <span style={{ color: '#5F5F5F', fontSize: '14px' }}>сервисом) </span>],
        }
    ]

    const { ref, inView } = useInView({
        threshold: 0,
    });

    return (
        <section className='app-main-progress'>
            {/*<img className='app-main-progress-img disappear' src={progress} alt=""/>*/}
            {/*<img className='app-main-progress-img appear' src={progressDisappear} alt=""/>*/}
            <div className="header-wrapper align-left">
                <h1>Полностью автономная работа</h1>
                <p className="page-description">экономит время инженера, технического надзора, облегчает работу
                    руководителям и приносит пользу собственникам.</p>
            </div>
            <div className="app-main-progress-content" ref={ref}>
                <div className="app-main-progress-img">
                    <img src={progressImg} alt="" className={inView ? 'animate' : 'show'}/>
                    <div className="app-main-progress-content-wrapper">
                        <div className="app-main-progress-content-inner-wrapper">
                            <div className="app-main-progress-loop">
                                {size.width > 1024 ?
                                    <>
                                        {items.map(item => (
                                            <div className={`app-main-progress-item ${item.key}`}>
                                                <div>
                                                    <img src={item.img} alt=""/>
                                                    {item.paragraphs.map(paragraph => (
                                                        <p>{paragraph}</p>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </> : <Slider items={items}/>}
                            </div>
                            <div className="header-wrapper">
                                <h1>Возьмите под контроль работу с замечаниями</h1>
                                <p className="page-description">Свяжись с нами и мы ответим на все интересующие вас
                                    вопросы</p>
                            </div>
                            <div>
                                <button className="blue-btn" onClick={() => openModal(true)}>Связаться с нами</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}