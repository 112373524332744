import React, {useState, useEffect} from 'react';
import '../styles/HeaderBlock.css';
import img1 from '../images/Header/img1.png';
import img2 from '../images/Header/img2.png';
import img1Mob from '../images/Header/img1-mob.png';
import img2Mob from '../images/Header/img2-mob.png';


export default function HeaderBlock({openDemonstrationModal, openBillModal, size}) {

    return (
        <section className='app-main-header'>
            <div className='app-main-header-content'>
                <img src={size.width < 768 ? img1Mob : img1} alt=''/>
                <img src={size.width< 768 ? img2Mob : img2} alt=''/>
                <h1>Мобильное приложение</h1>
                <h2>по мониторингу строительства</h2>
                <h3>МОНСТР — облачное решение для контроля</h3>
                <h3>за строительством и эксплуатацией объектов</h3>
                <div className='app-main-header-btns'>
                    <button className="white-btn" onClick={() => openDemonstrationModal(true)}>Бесплатная демонстрация</button>
                    <button className="transparent-btn" onClick={() => openBillModal(true)}>Рассчитать стоимость</button>
                </div>
            </div>
        </section>
    );
}