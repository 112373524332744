import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {ReactNotifications} from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<div className="app-container">
		<ReactNotifications/>
		<App/>
	</div>
);