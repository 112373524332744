import React, {useState, useEffect} from 'react';
import '../styles/SolutionBlock.css';
import '../styles/CheckboxWrapper.css';
import serviceIcon from '../images/Solution/serviceIcons.png';
import SecondSlider from "./SecondSlider";
import s11 from '../images/Solution/s11.svg';
import s11Mob from '../images/Solution/s11-mob.svg';
import s12 from '../images/Solution/s12.svg';
import s12Mob from '../images/Solution/s12-mob.svg';
import s13 from '../images/Solution/s13.svg';
import s13Mob from '../images/Solution/s13-mob.svg';
import s14 from '../images/Solution/s14.svg';
import s15 from '../images/Solution/s15.svg';
import s15Mob from '../images/Solution/s15-mob.svg';
import s21 from '../images/Solution/s21.svg';
import s21Mob from '../images/Solution/s21-mob.svg';
import s22 from '../images/Solution/s22.svg';
import s22Mob from '../images/Solution/s22-mob.svg';
import s23 from '../images/Solution/s23.svg';
import s23Mob from '../images/Solution/s23-mob.svg';
import s24 from '../images/Solution/s24.svg';
import s24Mob from '../images/Solution/s24-mob.svg';
import s25 from '../images/Solution/s25.svg';
import s31 from '../images/Solution/s31.svg';
import s32 from '../images/Solution/s32.svg';
import s33 from '../images/Solution/s33.svg';
import s32Mob from '../images/Solution/s32-mob.svg';
import s33Mob from '../images/Solution/s33-mob.svg';
import s34Mob from '../images/Solution/s34-mob.svg';
import s35Mob from '../images/Solution/s35-mob.svg';
import s41Mob from '../images/Solution/s41-mob.svg';
import s42 from '../images/Solution/s42.svg';
import s42Mob from '../images/Solution/s42-mob.svg';
import s43 from '../images/Solution/s43.svg';
import s1Img from '../images/Solution/solutionImg1.png';
import s1ImgMob from '../images/Solution/solutionImg1-mob.png';
import s2Img from '../images/Solution/solutionImg2.png';
import s2ImgMob from '../images/Solution/solutionImg2-mob.png';
import s3Img from '../images/Solution/solutionImg3.png';
import s31ImgMob from '../images/Solution/solutionImg3-1mob.png';
import s32ImgMob from '../images/Solution/solutionImg3-2mob.png';
import s4Img from '../images/Solution/solutionImg4.png';
import s4ImgMob from '../images/Solution/solutionImg4-mob.png';
import s1Icon from './Icons/Solution/FolderIcon';
import s2Icon from './Icons/InfoIcon';
import s3Icon from './Icons/Solution/PeopleIcon';
import s4Icon from './Icons/StatisticIcon';
import s1ActiveIconMob from '../images/Solution/solution1Active-mob.svg';
import s2ActiveIconMob from '../images/Solution/solution2Active-mob.svg';
import s3ActiveIconMob from '../images/Solution/solution3Active-mob.svg';
import s4ActiveIconMob from '../images/Solution/solution4Active-mob.svg';
import arrowUp from '../images/arrowUp.svg';
import arrowDown from '../images/arrowDown.svg';

export default function SolutionBlock({openDemonstrationModal, openBillModal, size, refProp}) {
	const [items, setItems] = useState([
		{
			key: 's1',
			title: 'Работа с объектами строительства',
			icon: s2Icon,
			iconMobActive: s2ActiveIconMob,
			checked: true,
			block: 'Контроль устранения замечаний',
			img: s2Img,
			mobImg: s2ImgMob,
			slider: [],
			arrows: [
				{
					text: 'Создание неограниченного числа проектов с необходимым количеством элементов',
					arr: s21,
					key: 's21',
					mobArr: s21Mob,
					mobVisibility: true,
				},
				{
					text: 'Прикрепление проектной/рабочей документации к элементам структуры',
					arr: s22,
					mobArr: s22Mob,
					key: 's22',
					mobVisibility: true,
				},
				{
					text: 'Назначение признака элементу: секция, этаж – для интеграции в акты выполненных работ в ERP-систему',
					arr: s23,
					key: 's23',
					mobArr: s23Mob,
					mobVisibility: true,
				},
				{
					text: 'Множественный перенос сроков/устранения/добавления замечаний в печатные формы',
					arr: s24,
					key: 's24',
					mobArr: s24Mob,
					mobVisibility: true,
				},
				{
					text: 'Отражение количества замечаний в цветовых индикаторах',
					arr: s25,
					key: 's25',
					mobVisibility: false,
				},
			]
		},
		{
			key: 's2',
			title: 'Контроль устранения замечаний',
			icon: s1Icon,
			iconMobActive: s1ActiveIconMob,
			checked: false,
			block: 'Выдача замечаний напрямую субподрядным организациям',
			img: s1Img,
			mobImg: s1ImgMob,
			slider: [],
			arrows: [
				{
					text: 'Загружаемые справочники НТД',
					arr: s11,
					mobArr: s11Mob,
					key: 's11',
					mobVisibility: true,
				},
				{
					text: 'Работа с фильтрами',
					arr: s12,
					mobArr: s12Mob,
					key: 's12',
					mobVisibility: true,
				},
				{
					text: 'Множественный перенос сроков/устранения/добавления замечаний в печатные формы',
					arr: s13,
					mobArr: s13Mob,
					key: 's13',
					mobVisibility: true,
				},
				{
					text: 'Смена ответственных за устранение замечаний',
					arr: s14,
					mobArr: s11Mob,
					key: 's14',
					mobVisibility: true,
				},
				{
					text: 'Выгрузка замечаний в форму предписания',
					arr: s15,
					mobArr: s15Mob,
					key: 's15',
					mobVisibility: true,
				},
			]
		},
		{
			key: 's3',
			title: 'Выдача замечаний напрямую субподрядным организациям',
			icon: s3Icon,
			iconMobActive: s3ActiveIconMob,
			checked: false,
			block: 'Доступность документации',
			img: s3Img,
			mobImg: s31ImgMob,
			arrows: [
				{
					text: 'Заполнение наименования и описания вручную или выбор из справочника НТД',
					arr: s31,
					key: 's31',
				},
				{
					text: 'Повторное открытие замечаний',
					arr: s32,
					key: 's32',
				},
				{
					text: 'Проставление индекса и условного номера для формирования листа осмотра',
					arr: s33,
					key: 's33',
				},
				{
					text: 'Выбор наименования замечания из справочника типовых нарушений',
					arr: s23,
					key: 's34',
				},
				{
					text: 'Выбор субподрядной организации для устранения предписания',
					arr: s25,
					key: 's35',
				},
			],
			slider: [{
				key: 's31-wrap',
				img: s31ImgMob,
				arrows: [{
					text: 'Назначение признака элементу: секция, этаж – для интеграции в акты выполненных работ в ERP-систему',
					arr: s21Mob,
					key: 's31-inn',
				},
					{
						text: 'Проставление индекса и условного номера для формирования листа осмотра ',
						arr: s32Mob,
						key: 's32-inn',
					},
					{
						text: 'Повторное открытие замечаний',
						arr: s33Mob,
						key: 's33-inn',
					},],
			}, {
				key: 's32-wrap',
				img: s32ImgMob,
				arrows: [{
					text: 'Заполнение наименования и описания вручную или выбор из справочника НТД',
					arr: s34Mob,
					key: 's34-inn',
				},
					{
						text: 'Выбор субподрядной организации для устранения предписания',
						arr: s35Mob,
						key: 's35-inn',
					},
				],
			},
			],
		},
		{
			key: 's4',
			title: 'Статистика',
			icon: s4Icon,
			iconMobActive: s4ActiveIconMob,
			checked: false,
			block: 'Статистика',
			img: s4Img,
			mobImg: s4ImgMob,
			slider: [],
			arrows: [
				{
					text: 'Сводный график по количеству замечаний',
					arr: s32,
					mobArr: s41Mob,
					key: 's41',
					mobVisibility: true,
				},
				{
					text: 'График выполнения и приемки работ ИСК/ОКК/ОТ',
					arr: s42,
					mobArr: s42Mob,
					key: 's42',
					mobVisibility: true,
				},
				{
					text: 'Рейтинг авторов по общему количеству замечаний',
					arr: s43,
					key: 's43',
					mobVisibility: false,
				},
			]
		},
	]);

	useEffect(() => {
		setItems(items);
	}, [size]);

	const onChange = (key, value) => {
		const newItems = items.map(item => {
			const newItem = {...item};
			newItem.checked = false;
			if (newItem.key === key) {
				newItem.checked = value;
			}
			return newItem;
		});
		setItems(newItems);
	}

	return (
		<section className='app-main-solutions' ref={refProp}>
			<div className="header-wrapper">
				<h1>Внедряем современные решения для эффективной работы</h1>
				<div className="page-description">
					<span>МонСтр – Работа сразу на 3 платформах:</span>
					<img src={serviceIcon} alt=""/>
				</div>
			</div>
			<div className="checkbox-wrapper">
				{items.map(item => (
					<div key={item.key} className={`checkbox-item ${item.key}`}>
						<div className={`checkbox-${item.checked ? 'active' : 'inActive'}`}>
							<input type="checkbox" name="header" id={item.key} checked={item.checked}
										 onClick={(e) => onChange(item.key, e.target.checked)}/>
							<label htmlFor={item.key}>
								<div>
									<item.icon color={item.checked ? 'rgba(76, 172, 233, 1)' : 'rgba(46, 55, 60, 0.6)'}/>
									<span>{item.title}</span>
								</div>
								{size.width <= 1024 ? <img src={item.checked ? arrowUp : arrowDown} alt=""/> : null}
							</label>
						</div>
						<div
							className={`checkbox-img ${item.checked ? 'active' : 'inActive'}`}>
							<div style={{position: 'relative'}}>
								{item.arrows.map(arrow => <div className={`text-wrapper ${arrow.key}`}>
									<div style={{display: size.width <= 1024 && !arrow.mobVisibility ? 'none' : 'flex'}}>{arrow.text}</div>
									<img style={{display: size.width <= 1024 && !arrow.mobVisibility ? 'none' : 'block'}}
											 src={size.width <= 1024 ? arrow.mobArr : arrow.arr} alt=""/>
								</div>)}
								{size.width <= 1024 && item.slider.length ? <SecondSlider size={size} items={item.slider}/> : <img src={size.width <= 1024 ? item.mobImg : item.img} alt=""/>}
							</div>
							<div>
								<button className="blue-btn" onClick={() => openDemonstrationModal(true)}>Бесплатная демонстрация
								</button>
								<button className="transparent-btn" onClick={() => openBillModal(true)}>Рассчитать
									стоимость
								</button>
							</div>
						</div>
					</div>
				))}
			</div>
		</section>
	);
}