import React, {useRef} from "react";
import Slider from "react-slick";
import '../styles/SecondSlider.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SecondSlider({items, size}) {
	let sliderRef = useRef(null);

	const settings = {
		infinite: false,
		dots: true,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		speed: 500,
		autoplaySpeed: 2000,
		cssEase: "linear",
		variableWidth: true,
		adaptiveHeight: true,
	};
	return (
		<div className="slider-container" style={{width: size.width * 0.9, margin: '0 auto'}}>
			<Slider {...settings} style={{width: size.width * 0.9}} ref={slider => (sliderRef = slider)}
							appendDots={(dots) => (
								<div>
									<div className="slider-dots-wrapper">
										<ul className="slider-dots"> {dots} </ul>
									</div>
								</div>
							)}>
				{items.map(item => (
					<div style={{width: size.width * 0.9}}>
						<div className={item.key}>
							<img src={item.img} alt=""/>
							{item.arrows.map(el => (
								<div className={el.key}>
									<img src={el.arr} alt=""/>
									<span>{el.text}</span>
								</div>
							))}
						</div>
					</div>
				))}
			</Slider>
		</div>
	);
}
