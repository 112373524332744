import React from 'react';
import '../styles/ContactBlock.css';
import phoneIcon from '../images/App/phone.svg';
import mapIcon from '../images/App/mapIcon.svg';
import clockIcon from '../images/App/clockIcon.svg'

export default function ContactBlock(props) {

	const items = [
		{
			key: 'c-1',
			title: 'Мы находимся по адресу',
			description: '197198, г. СПб, пр-т Добролюбова, д. 8, лит. А, пом. 1-Н, офис 306',
			icon: mapIcon,
			width: '270px',
		},
		{
			key: 'c-2',
			title: 'Режим работы',
			description: 'Пн.-Пт. с 9:00 до 18:00',
			icon: clockIcon,
			width: '180px',
		},
		{
			key: 'c-3',
			title: 'Контакты для связи',
			description: '+7 (812) 339-74-41',
			icon: phoneIcon,
			width: '180px',
		},
	];

	return (
		<section className="app-main-contacts" ref={props.refProp}>
			<div className="header-wrapper align-left">
				{props.size.width > 1200 ? <h1 className="align-left">Контакты</h1> : null}
			</div>
			<div className='app-main-contacts-container'>
				<div className='app-main-contacts-container-info'>
					{items.map(item => (
						<div className='app-main-contacts-container-info-item' key={item.key}>
							<h2>{item.title}</h2>
							<div>
								<img src={item.icon}/>
								<p style={{maxWidth: item.width, width: '100%'}}>{item.description}</p>
							</div>
						</div>
					))}
				</div>
				<div className='app-main-contacts-container-card'>
					<iframe title='map'
									src="https://yandex.ru/map-widget/v1/?um=constructor%3A4afc51cf183a744ad88c86a060d6406bb622fa38ad1c1733902867880d800732&amp;source=constructor"
									frameBorder="0"></iframe>
				</div>
			</div>
		</section>
	);
}